<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getPaymentReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-start">
            <div>
              <h2 id="paymentTitle" class="font-weight-normal mb-0">
                {{ $t('report.txtpaymentreport') }}
              </h2>
              <show-date class="mt-1"></show-date>
            </div>
              <HelpButton :isHeader="false" class="ml-2 mt-2"></HelpButton>
            </div>
            <div class="col-4 col-lg-2 col-4 text-right text-success" v-if="isExport">
              <CButton id="paymentExportButton" v-on:click="exportPaymentReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
              <CButton
                id="paymentKBankExportButton"
                v-if="enabledKBankQRPayment"
                v-on:click="exportKBankPaymentReport()"
                block
                color="info"
              >
              <i class="fi fi-rr-file-csv"></i> {{ $t('exportKBank') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <hr />
              <CRow v-if="data.length != 0">
                <CCol class="col-md-12">
                  <CCard class="d-flex align-items-center">
                    <CCardBody>
                      <CChartDoughnut
                        id="paymentChartDoughnut"
                        style="height:350px;"
                        :datasets="paymentChart.datasets"
                        :labels="paymentChart.labels"
                        :options="doughnutOptions"
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol class="col-3" v-if="total > 0">
                  <CCardBody  style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('payTotal') }}</h6>
                      <strong id="paymentTotal" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(total) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="cash > 0">
                  <CCardBody  style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('paymentType0') }}</h6>
                      <strong id="paymentCash" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(cash) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="promptpay > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('paymentType4') }}</h6>
                      <strong id="paymentpromtpay" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(promptpay) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="credit > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('paymentType1') }}</h6>
                      <strong id="paymentCredit" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(credit) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="kbank > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">KBank</h6>
                      <strong id="paymentKbank" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(kbank) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="edc > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">EDC</h6>
                      <strong id="paymentEDC" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(edc) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="coupon > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('paymentType2') }}</h6>
                      <strong id="paymentCoupon" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(coupon) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="alipay > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">Alipay</h6>
                      <strong id="paymentAlipay" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(alipay) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>
                <CCol class="col-3" v-if="delivery > 0">
                  <router-link
                      :to="{ name: 'Delivery' }"
                      replace
                      style="text-decoration: none;"
                    >
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('paymentType7') }}></h6>
                      <strong id="paymentDelivery" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(delivery) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                  </router-link>
                </CCol>
                <CCol class="col-3" v-if="custom > 0">
                  <router-link
                      :to="{ name: 'CustomPay' }"
                      replace
                      style="text-decoration: none;"
                      id="paymentCustomPayLink"
                    >
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('paymentType10') }}></h6>
                      <strong id="paymentCustom" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(custom) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                  </router-link>
                </CCol>
                <CCol class="col-3" v-if="etc > 0">
                  <CCardBody style="margin: 0px; padding: 0px;">
                    <CCallout color="success">
                      <h6 class="text-muted">{{ $t('otherPayment') }}</h6>
                      <strong id="paymentETC" class="h5 font-weight-normal text-success">
                        {{ convertCurrency(etc) }}
                      </strong>
                    </CCallout>
                  </CCardBody>
                </CCol>

              </CRow>

              <DataTable
                id="paymentDataTable"
                style="white-space: nowrap;"
                :items="items()"
                :fields="fields"
                :loading="loading"
                hover
                border
              >
              </DataTable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CChartDoughnut } from '@coreui/vue-chartjs'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import payment from '@/services/payment'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    CChartDoughnut,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      summary: {},
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    shop() {
      return this.shops.find((i) => i.objectId === this.shopObjectId)
    },
    enabledKBankQRPayment() {
      if (this.shop) {
        if (this.shop.enabledKBankQRPayment) {
          return true
        } else {
          return false
        }
      }
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    total() {
      return this.cash + this.promptpay + this.credit + this.kbank + this.edc + this.coupon + this.alipay + this.delivery + this.custom + this.etc
    },
    cash() {
      return this.summary.cash || 0
    },
    promptpay() {
      return this.summary.promptpay || 0
    },
    credit() {
      return this.summary.credit || 0
    },
    kbank() {
      return this.summary.kbank || 0
    },
    edc() {
      return this.summary.edc || 0
    },
    quickpay() {
      let quickpay = this.summary.quickpay
      if (quickpay) {
        return util.convertCurrency(quickpay)
      } else {
        return '0.00'
      }
    },
    coupon() {
      return this.summary.coupon || 0
    },
    alipay() {
      return this.summary.alipay || 0
    },
    delivery() {
      return this.summary.delivery || 0
    },
    custom() {
      return this.summary.customPay || 0
    },
    etc() {
      return this.summary.etc || 0
    },
    paymentChart() {
      const payment = this.summary
      const data = []
      const labels = []
      
      if ((payment.cash || 0) > 0) {
        labels.push(this.$i18n.t('paymentType0'))
        data.push(payment.cash)
      }
      if ((payment.credit || 0) > 0) {
        labels.push(this.$i18n.t('paymentType1'))
        data.push(payment.credit)
      }
      if ((payment.kbank || 0) > 0) {
        labels.push('KBank')
        data.push(payment.kbank)
      }
      if ((payment.edc || 0) > 0) {
        labels.push('EDC')
        data.push(payment.edc)
      }
      if ((payment.promptpay || 0) > 0) {
        labels.push(this.$i18n.t('paymentType4'))
        data.push(payment.promptpay)
      }
      if ((payment.delivery || 0) > 0) {
        labels.push(this.$i18n.t('paymentType7'))
        data.push(payment.delivery)
      }
      if ((payment.quickpay || 0) > 0) {
        labels.push(this.$i18n.t('paymentType3'))
        data.push(payment.quickpay)
      }
      if ((payment.alipay || 0) > 0) {
        labels.push(this.$i18n.t('paymentType5'))
        data.push(payment.alipay)
      }
      if ((payment.coupon || 0) > 0) {
        labels.push(this.$i18n.t('paymentType2'))
        data.push(payment.coupon)
      }
      if ((payment.onlineOrder || 0) > 0) {
        labels.push('Online order')
        data.push(payment.onlineOrder)
      }
      if ((payment.linePay || 0) > 0) {
        labels.push(this.$i18n.t('paymentType9'))
        data.push(payment.linePay)
      }
      if ((payment.customPay || 0) > 0) {
        labels.push(this.$i18n.t('paymentType10'))
        data.push(payment.customPay)
      }
      if ((payment.etc || 0) > 0) {
        labels.push(this.$i18n.t('otherPayment'))
        data.push(payment.etc)
      }

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: [
              '#5CBA96',
              '#F29089',
              '#4CB2D4',
              '#F6CE3B',
              '#A9A7D6',
              '#BA5C80',
              '#9992D1',
              '#D19992',
            ],
            data: data,
            borderWidth: 1,
          },
        ],
      }
    },
    doughnutOptions() {
      return {
        legend: {
          position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'),
          _classes: 'text-dark font-weight-normal' },
        {
          key: 'grandTotal',
          label: this.$i18n.t('payTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'cash',
          label: this.$i18n.t('paymentType0'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'promptpay',
          label: this.$i18n.t('paymentType4'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'credit',
          label: this.$i18n.t('paymentType1'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'kbank',
          label: this.$i18n.t('KBank'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'edc',
          label: this.$i18n.t('EDC'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        { key: 'quickpay', label: 'Quick Pay', _classes: 'text-right text-dark font-weight-normal', },
        {
          key: 'coupon',
          label: this.$i18n.t('paymentType2'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        { key: 'onlineOrder', label: 'Online', _classes: 'text-right text-dark font-weight-normal', },
        { key: 'alipay', label: 'Alipay', _classes: 'text-right text-dark font-weight-normal', },
        { key: 'delivery', label: 'Delivery', _classes: 'text-right  text-dark font-weight-normal',},
        {
          key: 'deposit',
          label: this.$i18n.t('deposit'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'customPay',
          label: this.$i18n.t('paymentType10'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'etc',
          label: this.$i18n.t('otherPayment'),
          _classes: 'text-right text-dark font-weight-normal',
        },
      ]
    },
    
  },
  created() {
    this.getPaymentReport()
  },
  methods: {
    ...util,
    items() {
      let data = this.data
      let detail = []
      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        detail.push({
          date: moment(String(item.date)).format('DD/MM/YYYY'),
          grandTotal: util.convertCurrency(item.grandTotal || 0),
          cash: util.convertCurrency(item.cash || 0),
          promptpay: util.convertCurrency(item.promptpay || 0),
          credit: util.convertCurrency(item.credit || 0),
          kbank: util.convertCurrency(item.kbank || 0),
          edc: util.convertCurrency(item.edc || 0),
          quickpay: util.convertCurrency(item.quickpay || 0),
          coupon: util.convertCurrency(item.coupon || 0),
          alipay: util.convertCurrency(item.alipay || 0),
          delivery: util.convertCurrency(item.delivery || 0),
          onlineOrder: util.convertCurrency(item.onlineOrder || 0),
          customPay: util.convertCurrency(item.customPay || 0),
          deposit: util.convertCurrency(item.deposit || 0),
          etc: util.convertCurrency(item.etc || 0),
        })
      }
      return detail
    },
    checkStoreId() {
      if (this.shop.storeId) {
        return this.shop.storeId
      } else {
        alert('ข้อมูลผิดพลาด ติดต่อฝ่ายบริการลูกค้า')
        return ''
      }
    },
    convertCurrency(value) {
      return util.convertCurrency(value)
    },
    getDelivery() {
      this.$router.push({ name: 'Delivery' })
    },
    getPaymentReport() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/payment/v1.0/getreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data.dates
          this.summary = res.data.data.summary
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportPaymentReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = { shopObjectId: shopObjectId }
      report({
        url: '/payment/v1.0/getreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานการชำระเงิน.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportKBankPaymentReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let storeId = this.checkStoreId()

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        storeId: storeId,
        fromDate: startAt,
        toDate: endAt,
      }

      if (storeId != '') {
        payment({
          url: '/api/v1.0/report/' + uid + '/kbankreport/csv',
          params: params,
          method: 'GET',
          responseType: 'blob', // important
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              'รายงานการชำระเงินคิวอาร์โค้ดกสิกร.csv'
            )
            document.body.appendChild(link)
            link.click()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>
